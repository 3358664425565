import React from "react";
import { Button } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";

export interface LoginProps {}

export const Login = ({}: LoginProps): JSX.Element => {
  const { loginWithPopup } = useAuth0();

  return (
    <Button
      fullWidth={true}
      color="primary"
      variant="outlined"
      onClick={() => {
        loginWithPopup();
      }}
    >
      Sign In
    </Button>
  );
};
