import React from "react";
import { Snackbar } from "@mui/material";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import * as consts from "../consts";

export interface NotificationProps {
  message: string;
  type: string;
  open: boolean;
  onDismiss: () => void;
}

const StyledAlert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export function Notification({
  message,
  type,
  open,
  onDismiss,
}: NotificationProps): JSX.Element {
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    } else {
      onDismiss();
    }
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      onClose={handleClose}
    >
      <StyledAlert
        onClose={handleClose}
        severity={type == consts.ALERT_TYPE_SUCCESS ? "success" : "error"}
        sx={{ width: "100%" }}
      >
        {message}
      </StyledAlert>
    </Snackbar>
  );
}
