import React from "react";
import { LinearProgress, Grid } from "@mui/material";
import * as consts from "../consts";

export interface BusyProps {}

export const Busy = ({}: BusyProps): JSX.Element => {
  return (
    <Grid
      item
      xs={12}
      container
      spacing={2}
      sx={{
        margin: "16px",
      }}
    >
      <Grid item xs={12} container spacing={2}>
        <Grid item xs={12} sx={{ textAlign: "center" }}>
          TALENT LABS
        </Grid>
        <Grid item xs={12}>
          <LinearProgress />
        </Grid>
      </Grid>
    </Grid>
  );
};
