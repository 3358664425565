import React from "react"; // eslint-disable-line no-unused-vars
import {
  ThemeProvider,
  ScopedCssBaseline,
  createTheme,
  Container,
} from "@mui/material";
import { light } from "../themes";
import { AuthProvider } from "../providers";
import { Main } from "./";
import { styled } from "@mui/system";

export interface AppProps {}

const lightTheme = createTheme(light);

const StyledContainer = styled(Container)(({ theme }) => ({
  padding: theme.spacing(2),
}));

export const App = ({}: AppProps): JSX.Element => {
  return (
    <AuthProvider>
      <ThemeProvider theme={lightTheme}>
        <ScopedCssBaseline>
          <StyledContainer disableGutters={true} maxWidth={false}>
            <Main />
          </StyledContainer>
        </ScopedCssBaseline>
      </ThemeProvider>
    </AuthProvider>
  );
};
