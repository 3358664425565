import React from "react"; // eslint-disable-line no-unused-vars
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import { Customer } from "../types";

export interface ResultsProps {
  rows: Customer[];
  onSelectCustomer: (customerId: number) => void;
}

export const Results = ({
  rows,
  onSelectCustomer,
}: ResultsProps): JSX.Element => {
  return (
    <TableContainer sx={{ maxHeight: 440 }}>
      <Table
        sx={{ minWidth: 750 }}
        aria-labelledby="tableTitle"
        size="medium"
        stickyHeader={true}
      >
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Name</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => {
            return (
              <TableRow
                key={index}
                onClick={() => {
                  onSelectCustomer(row.id);
                }}
                style={{ cursor: "pointer" }}
              >
                <TableCell>{row.id}</TableCell>
                <TableCell>{row.name}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
