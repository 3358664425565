import * as consts from "../consts";
import axios from "axios";
import { Customer } from "../types";

export const getCustomers = async (token: string): Promise<Customer[]> => {
  console.log(`Bearer ${token}`);
  let response = await axios.get(`${consts.SERVICE_URL}/customers`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

export const upload = async (
  customerId: number,
  file: File,
  token: string
): Promise<string> => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("id", customerId.toString());
  let response = await axios.post(`${consts.SERVICE_URL}/customers`, formData, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
    },
  });
  return response.data;
};
