import React, { useState, useEffect } from "react"; // eslint-disable-line no-unused-vars
import { Grid } from "@mui/material";
import { Customer } from "../types";
import { Login, Busy, Results, Upload, Notification } from "../components";
import { useAuth0 } from "@auth0/auth0-react";
import { getCustomers, upload } from "../utilities/actions";
import * as consts from "../consts";

export interface MainProps {}

export const Main = ({}: MainProps): JSX.Element => {
  const [busy, setBusy] = useState(true);
  const [rows, setRows] = useState<Customer[]>([]);
  const [selectedCustomerId, setSelectedCustomerId] = useState<number>(-1);
  const { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [notification, setNotification] = useState({ message: "", type: "" });

  const fetchResults = async () => {
    const get = async (): Promise<void> => {
      setBusy(true);
      let token = await getAccessTokenSilently();
      let results = await getCustomers(token);
      setRows(results);
      setBusy(false);
    };
    if (isAuthenticated) {
      get();
    } else {
      setBusy(false);
    }
  };

  useEffect(() => {
    fetchResults().catch(console.error);
  }, [isLoading]);

  return (
    <Grid item container xs={12} spacing={2}>
      <Notification
        message={notification.message}
        type={notification.type}
        open={notification.message.length > 0}
        onDismiss={() => {
          setNotification({ message: "", type: "" });
        }}
      />
      {isLoading || busy ? (
        <Busy />
      ) : (
        <React.Fragment>
          {isAuthenticated ? (
            <Grid item xs={12} container>
              <Grid item xs={12}>
                <Results
                  rows={rows}
                  onSelectCustomer={(customerId) => {
                    setSelectedCustomerId(customerId);
                  }}
                />
              </Grid>
              {selectedCustomerId > 0 && (
                <Grid item xs={12}>
                  <Upload
                    onUpload={async (file: File) => {
                      let token = await getAccessTokenSilently();
                      await upload(selectedCustomerId, file, token);
                      setNotification({
                        message: "File Uploaded Successfully",
                        type: consts.ALERT_TYPE_SUCCESS,
                      });
                    }}
                  />
                </Grid>
              )}
            </Grid>
          ) : (
            <Grid item xs={12}>
              <Login />
            </Grid>
          )}
        </React.Fragment>
      )}
    </Grid>
  );
};
