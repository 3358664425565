import React, { useState } from "react"; // eslint-disable-line no-unused-vars
import { Typography, Box, CircularProgress } from "@mui/material";
import Dropzone from "react-dropzone";

export interface UploadProps {
  onUpload: (file: File) => void;
}

export const Upload = ({ onUpload }: UploadProps): JSX.Element => {
  const [uploading, setUploading] = useState<boolean>(false);

  return (
    <Dropzone
      multiple={true}
      onDrop={async (files) => {
        if (files && files.length > 0) {
          setUploading(true);
          let url = await onUpload(files[0]);
          setUploading(false);
        }
      }}
    >
      {({ getRootProps, getInputProps }) => {
        if (uploading) {
          return (
            <Box sx={{ display: "flex" }}>
              <CircularProgress />
            </Box>
          );
        } else {
          return (
            <Box
              {...getRootProps()}
              sx={{
                textAlign: "center",
                backgroundColor: "#dfdfdf",
                padding: "5%",
                cursor: "pointer",
              }}
            >
              <input {...getInputProps()} />
              <Typography variant="body1">
                Drop file here or click to browse for your file
              </Typography>
            </Box>
          );
        }
      }}
    </Dropzone>
  );
};
